<template>
  <div class="add-form">
    <el-form
      :model="storageClassForm"
      ref="storageClassForm"
      size="small"
      label-position="top"
      :rules="storageClassRules"
    >
      <el-form-item label="存储卷名称" prop="metadata.name">
        <el-input v-model.trim="storageClassForm.metadata.name"></el-input>
      </el-form-item>

      <el-form-item :label="$t('location')" required>
        <checkout-list :data.sync="locationList" />
      </el-form-item>

      <el-form-item label="服务提供者">
        <el-radio-group v-model="storageClassForm.provisioner" @change="provisionerChange">
          <el-radio-button label="AWSElasticBlockStore"></el-radio-button>
          <el-radio-button label="GCEPersistentDisk"></el-radio-button>
          <el-radio-button label="Glusterfs"></el-radio-button>
          <el-radio-button label="RBD"></el-radio-button>
          <el-radio-button label="Local"></el-radio-button>
        </el-radio-group>
      </el-form-item>

      <div v-if="storageClassForm.provisioner == 'AWSElasticBlockStore'">
        <el-form-item label="fsType">
          <el-input v-model.trim="storageClassForm.parameters.fsType"></el-input>

          <span class="help">
            fsType that is supported by kubernetes. Default: "ext4".
          </span>
        </el-form-item>

        <el-form-item label="type">
          <el-input v-model.trim="storageClassForm.parameters.type"></el-input>

          <span class="help">
            io1, gp2, sc1, st1. See
            <a href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ebs-volume-types.html" target="_blank">
              AWS docs
            </a>
            for details. Default: gp2.
          </span>
        </el-form-item>
      </div>

      <div v-if="storageClassForm.provisioner == 'GCEPersistentDisk'">
        <el-form-item label="fstype">
          <el-input v-model.trim="storageClassForm.parameters.fstype"></el-input>
          <span class="help">
            ext4 or xfs. Default: ext4. The defined filesystem type must be supported by the host operating system.
          </span>
        </el-form-item>

        <el-form-item label="type">
          <el-input v-model.trim="storageClassForm.parameters.type"></el-input>
          <span class="help">
            pd-standard or pd-ssd. Default: pd-standard
          </span>
        </el-form-item>

        <el-form-item label="replication-type">
          <el-input v-model.trim="storageClassForm.parameters['replication-type']"></el-input>
          <span class="help">
            none or regional-pd. Default: none.
          </span>
        </el-form-item>
      </div>

      <div v-if="storageClassForm.provisioner == 'Glusterfs'">
        <el-form-item label="resturl">
          <el-input v-model.trim="storageClassForm.parameters.resturl"></el-input>
          <span class="help">
            Gluster REST service/Heketi service url which provision gluster volumes on demand. The general format should
            be IPaddress:Port and this is a mandatory parameter for GlusterFS dynamic provisioner. If Heketi service is
            exposed as a routable service in openshift/kubernetes setup, this can have a format similar to
            http://heketi-storage-project.cloudapps.mystorage.com where the fqdn is a resolvable Heketi service url.
          </span>
        </el-form-item>

        <el-form-item label="clusterid">
          <el-input v-model.trim="storageClassForm.parameters.clusterid"></el-input>
          <span class="help">
            630372ccdc720a92c681fb928f27b53f is the ID of the cluster which will be used by Heketi when provisioning the
            volume. <br />
            It can also be a list of clusterids, for example:
            "8452344e2becec931ece4e33c4674e4e,42982310de6c63381718ccfa6d8cf397". This is an optional parameter.
          </span>
        </el-form-item>

        <el-form-item label="restauthenabled">
          <el-input v-model.trim="storageClassForm.parameters.restauthenabled"></el-input>
          <span class="help">
            Gluster REST service authentication boolean that enables authentication to the REST server. If this value is
            "true", restuser and restuserkey or secretNamespace + secretName have to be filled. This option is
            deprecated, authentication is enabled when any of restuser, restuserkey, secretName or secretNamespace is
            specified.
          </span>
        </el-form-item>

        <el-form-item label="restuser">
          <el-input v-model.trim="storageClassForm.parameters.restuser"></el-input>
          <span class="help">
            Gluster REST service/Heketi user who has access to create volumes in the Gluster Trusted Pool.
          </span>
        </el-form-item>

        <el-form-item label="secretNamespace">
          <el-input v-model.trim="storageClassForm.parameters.secretNamespace"></el-input>
          <span class="help">
            Identification of Secret instance that contains user password to use when talking to Gluster REST service.
            These parameters are optional, empty password will be used when both secretNamespace and secretName are
            omitted.
          </span>
        </el-form-item>

        <el-form-item label="secretName">
          <el-input v-model.trim="storageClassForm.parameters.secretName"></el-input>
          <span class="help">
            Identification of Secret instance that contains user password to use when talking to Gluster REST service.
            These parameters are optional, empty password will be used when both secretNamespace and secretName are
            omitted.
          </span>
        </el-form-item>

        <el-form-item label="gidMin">
          <el-input v-model.trim="storageClassForm.parameters.gidMin"></el-input>
          <span class="help">
            The minimum value of GID range for the StorageClass.
            <br />
            A unique value (GID) in this range ( gidMin-gidMax ) will be used for dynamically provisioned volumes. These
            are optional values. If not specified, the volume will be provisioned with a value between 2000-2147483647
            which are defaults for gidMin and gidMax respectively.
          </span>
        </el-form-item>

        <el-form-item label="gidMax">
          <el-input v-model.trim="storageClassForm.parameters.gidMax"></el-input>
          <span class="help">
            The maximum value of GID range for the StorageClass.
          </span>
        </el-form-item>

        <el-form-item label="volumetype">
          <el-input v-model.trim="storageClassForm.parameters.volumetype"></el-input>
          <span class="help">
            The volume type and its parameters can be configured with this optional value. If the volume type is not
            mentioned, it's up to the provisioner to decide the volume type.
          </span>
        </el-form-item>
      </div>
      <div v-if="storageClassForm.provisioner == 'RBD'">
        <el-form-item label="monitors">
          <el-input v-model.trim="storageClassForm.parameters.monitors"></el-input>
          <span class="help">
            Ceph monitors, comma delimited. This parameter is required.
          </span>
        </el-form-item>

        <el-form-item label="adminId">
          <el-input v-model.trim="storageClassForm.parameters.adminId"></el-input>
          <span class="help">
            Ceph client ID that is capable of creating images in the pool. Default is "admin".
          </span>
        </el-form-item>

        <el-form-item label="adminSecretName">
          <el-input v-model.trim="storageClassForm.parameters.adminSecretName"></el-input>
          <span class="help">
            Secret Name for adminId. This parameter is required. The provided secret must have type "kubernetes.io/rbd".
          </span>
        </el-form-item>

        <el-form-item label="adminSecretNamespace">
          <el-input v-model.trim="storageClassForm.parameters.adminSecretNamespace"></el-input>
          <span class="help">
            The namespace for adminSecretName. Default is "default".
          </span>
        </el-form-item>

        <el-form-item label="pool">
          <el-input v-model.trim="storageClassForm.parameters.pool"></el-input>
          <span class="help">
            Ceph RBD pool. Default is "rbd".
          </span>
        </el-form-item>

        <el-form-item label="userId">
          <el-input v-model.trim="storageClassForm.parameters.userId"></el-input>
          <span class="help">
            Ceph client ID that is used to map the RBD image. Default is the same as adminId.
          </span>
        </el-form-item>

        <el-form-item label="userSecretName">
          <el-input v-model.trim="storageClassForm.parameters.userSecretName"></el-input>
          <span class="help">
            The name of Ceph Secret for userId to map RBD image. It must exist in the same namespace as PVCs. This
            parameter is required.
          </span>
        </el-form-item>

        <el-form-item label="userSecretNamespace">
          <el-input v-model.trim="storageClassForm.parameters.userSecretNamespace"></el-input>
          <span class="help">
            The namespace for userSecretName.
          </span>
        </el-form-item>

        <el-form-item label="fsType">
          <el-input v-model.trim="storageClassForm.parameters.fsType"></el-input>
          <span class="help">
            fsType that is supported by kubernetes. Default: "ext4".
          </span>
        </el-form-item>

        <el-form-item label="imageFormat">
          <el-input v-model.trim="storageClassForm.parameters.imageFormat"></el-input>
          <span class="help">
            Ceph RBD image format, "1" or "2". Default is "2".
          </span>
        </el-form-item>

        <el-form-item label="imageFeatures">
          <el-input v-model.trim="storageClassForm.parameters.imageFeatures"></el-input>
          <span class="help">
            This parameter is optional and should only be used if you set imageFormat to "2". Currently supported
            features are layering only. Default is "", and no features are turned on.
          </span>
        </el-form-item>
      </div>

      <el-form-item label="卷回收策略">
        <el-radio-group v-model="storageClassForm.reclaimPolicy">
          <el-radio-button label="Retain"></el-radio-button>
          <el-radio-button label="Delete"></el-radio-button>
          <el-radio-button label="Recycle"></el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="卷挂载策略">
        <el-radio-group v-model="storageClassForm.volumeBindingMode">
          <el-radio-button label="WaitForFirstConsumer"></el-radio-button>
          <el-radio-button label="Immediate"></el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" plain @click="submit" size="small" :loading="loading">
          {{ $t("handle.submit") }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { storageClassSubmit } from "api/storageClass";
import CheckoutList from "@/components/CheckoutList";
export default {
  components: {
    CheckoutList
  },
  data() {
    return {
      loading: false,
      locationList: [],
      storageClassForm: {
        apiVersion: "storage.k8s.io/v1",
        kind: "StorageClass",
        metadata: {
          name: ""
        },
        parameters: {
          fsType: "ext4",
          type: "gp2"
        },
        provisioner: "AWSElasticBlockStore",
        reclaimPolicy: "Retain",
        volumeBindingMode: "WaitForFirstConsumer"
      },
      storageClassRules: {
        "metadata.name": { required: true, message: this.$t("rules.require"), trigger: "blur" }
      }
    };
  },

  methods: {
    provisionerChange(value) {
      switch (value) {
        case "AWSElasticBlockStore":
          this.$set(this.storageClassForm, "parameters", {
            fsType: "ext4",
            type: "gp2"
          });
          break;

        case "GCEPersistentDisk":
          this.$set(this.storageClassForm, "parameters", {
            type: "pd-standard",
            fstype: "ext4",
            "replication-type": "none"
          });
          break;

        case "Glusterfs":
          this.$set(this.storageClassForm, "parameters", {
            resturl: "http://127.0.0.1:8081",
            clusterid: "630372ccdc720a92c681fb928f27b53f",
            restauthenabled: "true",
            restuser: "admin",
            secretNamespace: "default",
            secretName: "heketi-secret",
            gidMin: "40000",
            gidMax: "50000",
            volumetype: "replicate:3"
          });
          break;

        case "RBD":
          this.$set(this.storageClassForm, "parameters", {
            monitors: "",
            adminId: "kube",
            adminSecretName: "",
            adminSecretNamespace: "",
            pool: "kube",
            userId: "kube",
            userSecretName: "",
            userSecretNamespace: "",
            fsType: "ext4",
            imageFormat: "2",
            imageFeatures: ""
          });
          break;

        case "Local":
          this.$set(this.storageClassForm, "parameters", {});
          break;
      }
    },

    submit() {
      this.$refs["storageClassForm"].validate(valid => {
        if (valid) {
          this.loading = true;
          storageClassSubmit(this.storageClassForm, {
            vendor: this.locationList[0],
            region: this.locationList[1],
            cluster: this.locationList[2]
          }).then(response => {
            this.loading = false;
            if (response.code === 0) {
              this.$router.push({ path: `/list/StorageClass` });
            }
          });
        }
      });
    }
  }
};
</script>
